import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import toast from "react-hot-toast";

const MenuOrderSummaryUserForm = (props) => {
  const {
    tenantApiBaseUrl,
    csrfToken,
    items,
    hours,
    getOrderTotalCost,
    getCheckoutOffer,
  } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { handleSubmit, control, formState } = useForm({
    name: "",
    phone: "",
    time: hours[0],
    coupon: null,
  });

  const getOrderData = () => {
    let checkoutBody = {
      checkout_data: {
        checkout_offers: getCheckoutOffer(),
        items: [],
        quantity: items.length,
        subtotal: parseFloat(getOrderTotalCost("subtotal")),
        tip: parseFloat(getOrderTotalCost("tip")),
        discount: parseFloat(getOrderTotalCost("discount")),
        tax: parseFloat(getOrderTotalCost("tax")),
        total: parseFloat(getOrderTotalCost("total")),
      },
    };

    items.forEach((item) => {
      const selectedMenuItem = item.selectedMenuItem;
      let itemData = {
        id: selectedMenuItem.id,
        name: selectedMenuItem.name,
        description: selectedMenuItem.description,
        instruction: item.instructions,
        spice_level: item.spiceLevel,
        count: item.quantity,
        price: parseFloat(selectedMenuItem.price),
        subtotal: parseFloat(item.itemTotal),
        options: item.options,
      };
      checkoutBody.checkout_data.items.push(itemData);
    });

    return checkoutBody;
  };

  const onSubmit = async (formData) => {
    const { name, phone, time, coupon } = formData;

    if (formState.isSubmitting) {
      return;
    }

    if (!name || !phone || !time) {
      setIsSubmitting((_) => false);
      return toast.error("Please complete the order details.");
    }

    const orderUrl = `${tenantApiBaseUrl}/api/orders`;
    const submitResult = await fetch(orderUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        order_url: window.location.origin,
        name: name,
        phone: phone,
        time: time,
        coupon_code: coupon,
        data: getOrderData(),
      }),
    });

    const resp = await submitResult.json();
    if (resp && resp.success && resp.data && resp.order_url) {
      window.location.href = resp.order_url;
    } else if (resp && !resp.success) {
      var message = resp.message
        ? resp.message
        : "Failed to create your order. Please try again later!";
      toast.error(message);
      setIsSubmitting((_) => false);
    }
  };

  const handleSubmitWrapper = (event) => {
    event.preventDefault();

    if (!isSubmitting) {
      setIsSubmitting((_) => true);
      handleSubmit(onSubmit)(event);
    }
  };

  return (
    <Form onSubmit={handleSubmitWrapper}>
      <Form.Group className="mb-3">
        <Controller
          name="name"
          control={control}
          render={({ field: { onChange } }) => {
            return (
              <Form.Control
                as="input"
                onChange={onChange}
                placeholder="Your Full Name"
              />
            );
          }}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Controller
          name="phone"
          control={control}
          render={({ field: { onChange } }) => {
            return (
              <Form.Control
                as="input"
                onChange={onChange}
                placeholder="Phone Number"
              />
            );
          }}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className="mr-2">
          <span>Select Pickup Time</span>
        </Form.Label>
        <Controller
          name="time"
          control={control}
          defaultValue={hours[0]}
          render={({ field: { onChange, value, defaultValue } }) => {
            return (
              <Form.Select
                onChange={onChange}
                value={value}
                defaultValue={defaultValue}
              >
                {hours.map((hour, index) => {
                  return (
                    <option key={index} value={hour}>
                      {hour}
                    </option>
                  );
                })}
              </Form.Select>
            );
          }}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Controller
          name="coupon"
          control={control}
          render={({ field: { onChange } }) => {
            return (
              <Form.Control
                as="input"
                onChange={onChange}
                placeholder="Enter Coupon Code"
              />
            );
          }}
        />
      </Form.Group>
      {props.isModal ? (
        <div className="pull-right">
          <Button
            className="outline mr-2"
            variant="secondary"
            onClick={props.handleModalClose}
          >
            Cancel
          </Button>
          <Button className="btn_1 gradient" variant="primary" type="submit">
            Checkout
          </Button>
        </div>
      ) : (
        <Button
          className="full-width btn_1 gradient"
          variant="primary"
          type="submit"
          disabled={isSubmitting}
        >
          Checkout
        </Button>
      )}
    </Form>
  );
};

export default MenuOrderSummaryUserForm;
