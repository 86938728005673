import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import DatePicker from "react-datepicker";
import AlertModal from "../shared/alert_modal";

const Form = (props) => {
  const { tenantApiBaseUrl, availableTimes, reservationEnabled, csrfToken } =
    props;
  const startOfToday = new Date(new Date().setHours(0, 0, 0, 0));

  const [startDate, setStartDate] = useState(startOfToday);
  const [selectAvailableTimes, setSelectAvailableTimes] =
    useState(availableTimes);

  const fetchAvailableTimes = async (date) => {
    const response = await fetch(
      `${tenantApiBaseUrl}/api/hours/options?date=${date}`
    );
    const jsonData = await response.json();
    setSelectAvailableTimes(
      jsonData.data.available_hours.map((time) => time.display_time)
    );
  };

  const isValidDate = (date) => {
    return date.getTime() >= startOfToday.getTime();
  };
  const formatDate = (date) => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  const handleSelectedDateChange = (date) => {
    setStartDate(date);
    fetchAvailableTimes(formatDate(date));
  };

  const reservationErrorToast = (message) => {
    message =
      message || "Unable to create reservation. Please try again later.";
    toast.error(message, { duration: 3000 });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!reservationEnabled) {
      reservationErrorToast(
        "Reservations are currently disabled. Please try again later."
      );
      return;
    }

    fetch(`${tenantApiBaseUrl}/api/reservations`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        name: event.target.name.value,
        date: formatDate(startDate),
        time: event.target.time.value,
        phone_number: event.target.phone_number.value,
        party_size: event.target.party_size.value,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          reservationErrorToast();
          return;
        }

        const jsonData = await response.json();
        const reservationId = jsonData.data.id;

        if (reservationId) {
          const redirectUrl = `/reservations/${reservationId}`;
          toast.success("Your reservation has been created!", {
            duration: 1000,
          });
          setTimeout(() => {
            window.location.replace(redirectUrl);
          }, 1000);
        } else {
          reservationErrorToast();
        }
      })
      .catch(() => {
        reservationErrorToast();
      });
  };

  return (
    <>
      <form
        id="reserve-form"
        className="reserve-form form"
        method="post"
        action={`${tenantApiBaseUrl}/api/reservations`}
        onSubmit={handleSubmit}
      >
        <div id="form-messages" />
        <div className="row text-center">
          <div className="datepicker-group col-12 col-md-6 form-group">
            <div className="form-group-inner">
              <label className="sr-only" htmlFor="cdate">
                Date
              </label>
              <DatePicker
                className="form-control add-padding"
                filterDate={isValidDate}
                selected={startDate}
                onChange={handleSelectedDateChange}
              />
            </div>
          </div>
          <div className="timepicker-group col-12 col-md-6 form-group">
            <div className="form-group-inner">
              <label className="sr-only" htmlFor="ctime">
                Time
              </label>
              <select name="time" id="ctime" className="form-control">
                {selectAvailableTimes.map((time) => (
                  <option key={time} value={time}>
                    {time}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="name-group col-12 col-md-6 form-group">
            <div className="form-group-inner">
              <label className="sr-only" htmlFor="cname">
                Name
              </label>
              <input
                type="text"
                className="form-control add-padding"
                id="cname"
                name="name"
                placeholder="Your Full Name"
                minLength={2}
                aria-required="true"
                required=""
              />
            </div>
          </div>
          <div className="phone-group col-12 col-md-6 form-group">
            <div className="form-group-inner">
              <label className="sr-only" htmlFor="cphone">
                Phone
              </label>
              <input
                type="tel"
                className="form-control add-padding"
                id="cphone"
                name="phone_number"
                placeholder="Your Phone Number"
                aria-required="true"
                required=""
              />
            </div>
          </div>
          <div className="guest-group col-12 col-md-6 form-group">
            <div className="form-group-inner">
              <label className="sr-only" htmlFor="cguests">
                Guests
              </label>
              <select
                className="form-control add-padding"
                id="cguests"
                name="party_size"
                aria-required="true"
                required={true}
                selected="1"
              >
                <option value={1}>1 Person</option>
                <option value={2}>2 People</option>
                <option value={3}>3 People</option>
                <option value={4}>4 People</option>
                <option value={5}>5 People</option>
                <option value={6}>6 People</option>
                <option value={7}>7 People</option>
                <option value={8}>8 People</option>
                <option value={9}>9 People</option>
                <option value={10}>10+ People</option>
              </select>
            </div>
          </div>
          <div>
            <input type="hidden" name="authenticity_token" value={csrfToken} />
          </div>
        </div>
        <button type="submit" className="btn_1 btn-block btn-primary">
          Book Table Now
        </button>
      </form>
      {!reservationEnabled && (
        <AlertModal
          title="Important Notice"
          body="Reservations are currently disabled. Please try again later."
          dismissText="OK"
        />
      )}
      <Toaster position="top-right" />
    </>
  );
};

export default Form;
