import React, { createContext, useContext, useState, useEffect } from "react";
import StickyBox from "react-sticky-box";
import Modal from "react-bootstrap/Modal";
import MenuItemSelectionModal from "./menuItemSelectionModal";
import MenuOrderSummary from "./menuOrderSummary";

const MenuItemSelectionModalContext = createContext({});

const storeItemsInLocalStorage = (items) => {
  localStorage.setItem("ydc-expiration", Date.now() + 86400000);
  localStorage.setItem("ydc-items", JSON.stringify(items));
};

const setItemsFromLocalStorage = (setterCallback) => {
  const expiration = localStorage.getItem("ydc-expiration");
  if (!expiration || Date.now() > expiration) {
    localStorage.removeItem("ydc-items");
    localStorage.removeItem("ydc-expiration");
    return;
  }

  const storedItems = JSON.parse(localStorage.getItem("ydc-items"));
  if (storedItems) {
    setterCallback(storedItems);
  }
};

const MenuItem = (props) => {
  const { item } = props;
  if (!item) {
    return null;
  }

  let truncatedDescription = item.description;
  if (item.description && item.description.length > 95) {
    truncatedDescription = item.description.slice(0, 95) + "...";
  }
  const menuItemSelectionModalContext = useContext(
    MenuItemSelectionModalContext
  );

  return (
    <div className="col-md-6" style={{ cursor: "pointer" }}>
      <a
        className="menu_item"
        onClick={() =>
          menuItemSelectionModalContext.handleMenuItemSelection(item)
        }
      >
        <h3>{item.name}</h3>
        <p>{truncatedDescription}</p>
        <strong>${item.price}</strong>
      </a>
    </div>
  );
};

const MenuCategory = (props) => {
  const { category } = props;

  if (!category) {
    return null;
  }

  const categorySlice = category.children[0];
  const categoryItems = categorySlice.children;

  return (
    <section id={category.id}>
      <h4>{category.name}</h4>
      <div className="row pt-2">
        {categoryItems.map((item) => {
          return <MenuItem key={item.id} item={item} />;
        })}
      </div>
    </section>
  );
};

const MenuSection = (props) => {
  const {
    tenantApiBaseUrl,
    checkoutOffers,
    csrfToken,
    menu,
    hours,
    promoRate,
    taxRate,
  } = props;
  const menuCategories = menu ? menu.children : null;
  const displayHours = hours.map((hour) => hour.display_time);

  if (!menuCategories) {
    return null;
  }

  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [showMenuItemSelectionModal, setShowItemSelectionModal] =
    useState(false);
  const [items, setItems] = useState([]);
  const [showMenuItemSummaryModal, setShowMenuItemSummaryModal] =
    useState(false);
  const [showCart, setShowCart] = useState(true);

  // Load items from local storage on initial render
  useEffect(() => {
    setItemsFromLocalStorage(setItems);
  }, []);

  // Handler for when a menu item is clicked
  const handleMenuItemSelection = (item) => {
    setSelectedMenuItem(item);
    setShowItemSelectionModal(true);
    setShowMenuItemSummaryModal(false);
  };

  // Handlers for the menu item selection modal
  const handleModalClose = () => {
    setSelectedMenuItem(null);
    setShowItemSelectionModal(false);
  };
  const handleModalAction = (formValues) => {
    const finalItems = [...items, formValues];
    setItems(finalItems);
    storeItemsInLocalStorage(finalItems);

    // Clear the modal state
    setSelectedMenuItem(null);
    setShowItemSelectionModal(false);
  };

  const handleRemoveItem = (itemIndex) => {
    const finalItems = items.filter((_item, index) => index !== itemIndex);
    setItems(finalItems);
    storeItemsInLocalStorage(finalItems);
  };

  const handleSummaryModalOpen = () => {
    setShowCart(false);
    setShowMenuItemSummaryModal(true);
    setShowItemSelectionModal(false);
  };
  const handleSummaryModalClose = () => {
    setShowMenuItemSummaryModal(false);
    setShowCart(true);
  };

  const itemsCountText =
    items.length === 1 ? `1 item` : `${items.length} items`;

  return (
    <>
      <div className="bg_gray">
        <div className="container margin_detail">
          <div className="row">
            <div className="col-lg-8 list_menu">
              <MenuItemSelectionModalContext.Provider
                value={{ handleMenuItemSelection }}
              >
                {menuCategories.map((category) => (
                  <MenuCategory key={category.id} category={category} />
                ))}
              </MenuItemSelectionModalContext.Provider>
            </div>
            {!showMenuItemSummaryModal && (
              <div className="col-lg-4 d-none d-lg-block">
                <StickyBox offsetTop={90}>
                  <MenuOrderSummary
                    tenantApiBaseUrl={tenantApiBaseUrl}
                    checkoutOffers={checkoutOffers}
                    csrfToken={csrfToken}
                    items={items}
                    hours={displayHours}
                    promoRate={promoRate}
                    taxRate={taxRate}
                    handleRemoveItem={handleRemoveItem}
                  />
                </StickyBox>
              </div>
            )}
          </div>
        </div>
      </div>
      {showCart && (
        <div className="d-md-block d-lg-none btn_reserve_fixed">
          <a
            href="#"
            className="btn_1 gradient full-width"
            onClick={handleSummaryModalOpen}
          >
            <i className="fa fa-shopping-cart"></i> View Cart:{" "}
            <span>{itemsCountText}</span>
          </a>
        </div>
      )}
      <MenuItemSelectionModal
        size="lg"
        show={showMenuItemSelectionModal}
        selectedMenuItem={selectedMenuItem}
        handleModalAction={handleModalAction}
        handleModalClose={handleModalClose}
      />
      <div className="d-none d-sm-block d-md-none">
        <Modal show={showMenuItemSummaryModal} onHide={handleSummaryModalClose}>
          <Modal.Body>
            <MenuOrderSummary
              tenantApiBaseUrl={tenantApiBaseUrl}
              checkoutOffers={checkoutOffers}
              csrfToken={csrfToken}
              items={items}
              hours={displayHours}
              promoRate={promoRate}
              taxRate={taxRate}
              handleRemoveItem={handleRemoveItem}
              handleModalClose={handleSummaryModalClose}
              isModal
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default MenuSection;
