import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import ReactLoading from "react-loading";
import CustomerDetail from "./customerDetail";
import OrderDetail from "./orderDetail";

const POLLING_INTERVAL = 7000;
const Show = (props) => {
  const [loading, setLoading] = useState(true);
  const [orderDetail, setOrderDetail] = useState();
  const {
    tenantApiBaseUrl,
    tenantName,
    tenantAddress,
    order,
    invalidPickupTime,
    restaurantUrl,
    paymentUrl,
    csrfToken,
  } = props;

  const fetchOrder = async (id) => {
    try {
      const response = await fetch(`${tenantApiBaseUrl}/api/orders/${id}`);
      const json = await response.json();
      const orderDetail = json.data.order;
      setOrderDetail(orderDetail);
      setLoading(false);

      if (orderDetail.status == "PENDING") {
        setTimeout(() => {
          fetchOrder(id);
        }, POLLING_INTERVAL);
      }
    } catch (error) {
      toast.error("Unable to load order detail. Please try again later");
    }
  };

  // Initial fetch
  useEffect(() => {
    fetchOrder(order.id);
  }, []);

  return (
    <main>
      <div className="container mt-4">
        <div className="row justify-content-center">
          <div className="col-md-8 col-sm-12">
            {loading && (
              <div className="row d-flex justify-content-center flex-nowrap">
                <ReactLoading type="spokes" color="#00000" delay={100} />
              </div>
            )}
            {!loading && (
              <>
                <div className="head pb-2">
                  <div className="title">
                    <h3>{tenantName}</h3>
                  </div>
                  {tenantAddress && (
                    <h6 className="text-secondary mt-3">{tenantAddress}</h6>
                  )}
                </div>
                <CustomerDetail order={order} />
                {orderDetail && (
                  <OrderDetail
                    order={orderDetail}
                    invalidPickupTime={invalidPickupTime}
                    restaurantUrl={restaurantUrl}
                    paymentUrl={paymentUrl}
                    csrfToken={csrfToken}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Toaster position="top-right" />
    </main>
  );
};

export default Show;
