import React from "react";
import StickyBox from "react-sticky-box";
import { Link } from "react-scroll";
import Nav from "react-bootstrap/Nav";
import { Container } from "react-bootstrap";

const CategoryHeader = (props) => {
  const menu = props.menu || {};
  let menuCategories = [];

  // Build menu categories list
  if (menu.children) {
    for (let child in menu.children) {
      let node = menu.children[child];
      if (node && node.type == "category") {
        menuCategories.push({
          id: node.id,
          name: node.name,
        });
      }
    }
  }

  const listCategories = menuCategories.map((category) => (
    <li key={category.id}>
      <Link
        style={{ cursor: "pointer" }}
        to={category.id}
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
      >
        {category.name}
      </Link>
    </li>
  ));

  return (
    <StickyBox style={{ zIndex: 100 }}>
      <Nav className="secondary_nav">
        <Container>
          <ul>{listCategories}</ul>
        </Container>
      </Nav>
    </StickyBox>
  );
};

export default CategoryHeader;
