import React from "react";

const Show = (props) => {
  const { tenantName, tenantAddress, tenantUrl, reservation, reservationUrl } =
    props;

  let reservationMessage;
  switch (reservation.status) {
    case ("NEW", "CONFIRMED", "PENDING"):
      reservationMessage =
        "Thank you for your reservation. We look forward to seeing you soon!";
      break;
    case "COMPLETED":
      reservationMessage =
        "Your reservation has been completed. We hope you enjoyed it.";
      break;
    case "CANCELLED":
      reservationMessage = "Your reservation has been cancelled.";
      break;
    case "FAILED":
      reservationMessage =
        "Your reservation could not be processed. Please try again later.";
      break;
  }
  return (
    <main>
      <div className="container margin_60_20">
        <div className="row justify-content-center">
          <div className="col-md-8 col-sm-12">
            <div className="head pb-4">
              <div className="title">
                <h3>{tenantName}</h3>
                {tenantAddress && (
                  <>
                    <br />
                    <h5>{tenantAddress}</h5>
                  </>
                )}
              </div>
            </div>
            <div className="box_order_form">
              <div className="main">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="pb-2">Name</label>
                      <input
                        className="form-control"
                        placeholder={reservation.name}
                        disabled
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="pb-2">Phone Number</label>
                      <input
                        className="form-control"
                        placeholder={reservation.display_phone_number}
                        disabled
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="pb-2">Date</label>
                      <input
                        className="form-control"
                        placeholder={reservation.date}
                        disabled
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="pb-2">Time</label>
                      <input
                        className="form-control"
                        placeholder={reservation.time}
                        disabled
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="pb-2">Group Size</label>
                      <input
                        className="form-control"
                        placeholder={reservation.party_size}
                        disabled
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    <span>{reservationMessage}</span>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <div className="text-center">
                      <a className="btn_1 outline mr-2" href={reservationUrl}>
                        Start New Reservation
                      </a>
                      <a className="btn_1 outline" href={tenantUrl}>
                        Start New Order
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Show;
