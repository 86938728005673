import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FormGroup } from "react-bootstrap";

const CustomerDetail = (props) => {
  const { order } = props;

  return (
    <div className="box_order_form">
      <div>
        <Row>
          <Col md={6}>
            <FormGroup className="pt-2">
              <label className="pb-2 text-muted">Name</label>
              <input
                type="text"
                className="form-control"
                value={order.name}
                readOnly
                disabled
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className="pt-2">
              <label className="pb-2 text-muted">Phone Number</label>
              <input
                type="text"
                className="form-control"
                value={order.display_phone_number}
                readOnly
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup className="pt-2">
              <label className="pb-2 text-muted">Date</label>
              <input
                type="text"
                className="form-control"
                value={order.date}
                readOnly
                disabled
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className="pt-2">
              <label className="pb-2 text-muted">Time</label>
              <input
                type="text"
                className="form-control"
                value={order.time}
                readOnly
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup className="pt-2">
              <label className="pb-2 text-muted">Type</label>
              <input
                type="text"
                className="form-control"
                value={order.order_type}
                readOnly
                disabled
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className="pt-2">
              <label className="pb-2 text-muted">Reference Number</label>
              <input
                type="text"
                className="form-control"
                value={order.sequential_id}
                readOnly
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CustomerDetail;
