import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Modal, Button } from "react-bootstrap";
import MainHeader from "../shared/main_header";
import CategoryHeader from "./categoryHeader";
import MenuSection from "./menuSection";

const Show = (props) => {
  const {
    tenantApiBaseUrl,
    csrfToken,
    checkoutEnabled,
    availabeTimes,
    promoRate,
    taxRate,
  } = props;
  const [data, setData] = useState();
  const [checkoutOffers, setCheckoutOffers] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  let menu;

  // Load initial data
  useEffect(() => {
    if (availabeTimes.length === 0 || !checkoutEnabled) {
      setShow(true);
    }

    const fetchData = async () => {
      try {
        const [menuResponse, checkoutOffersResponse] = await Promise.all([
          fetch(`${tenantApiBaseUrl}/api/menu`),
          fetch(`${tenantApiBaseUrl}/api/checkout_offers`),
        ]);

        const menuJson = await menuResponse.json();
        const checkoutOffersJson = await checkoutOffersResponse.json();

        setData(menuJson.data);
        if (checkoutOffersJson.data && checkoutOffersJson.data.options) {
          setCheckoutOffers(checkoutOffersJson.data.options);
        }
      } catch (error) {
        toast.error("Unable to load menu items. Please try again later");
      }
    };

    if (tenantApiBaseUrl) {
      fetchData();
    }
  }, []);

  if (data) {
    menu = data.menu;
  }

  return (
    <main>
      <MainHeader {...props} />
      <CategoryHeader menu={menu} />
      <MenuSection
        menu={menu}
        checkoutOffers={checkoutOffers}
        hours={availabeTimes}
        tenantApiBaseUrl={tenantApiBaseUrl}
        csrfToken={csrfToken}
        promoRate={promoRate}
        taxRate={taxRate}
      />
      <Toaster position="top-right" />
      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>We are closed for today</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          There are no pickup times available at this time. Please check back
          later.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} className="outline">
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </main>
  );
};

export default Show;
