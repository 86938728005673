import React from "react";
import { useForm, useWatch, Controller } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import cloneDeep from "lodash.clonedeep";

const displayCurrency = (value) => {
  return `$${value.toFixed(2)}`;
};

const round = (value, decimals) => {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
};

const applyAdjustments = (selectedMenuItem, changes) => {
  const quantity = changes.quantity || 1;

  // Compute the applicable option categories and their prices
  const optionCategoryIds = [];
  const optionLookup = {};
  const categoryDefaultOptionLookup = {};
  if (selectedMenuItem.category_options) {
    selectedMenuItem.category_options.forEach((optionCategory) => {
      const optionCategoryId = optionCategory.id;
      optionCategoryIds.push(optionCategoryId);
      if (optionCategory.options) {
        optionCategory.options.forEach((option) => {
          if (!categoryDefaultOptionLookup.hasOwnProperty(optionCategoryId)) {
            categoryDefaultOptionLookup[optionCategoryId] = option;
          }
          optionLookup[option.id] = option;
        });
      }
    });
  }
  // Set the default options hash
  if (!selectedMenuItem.hasOwnProperty("options")) {
    selectedMenuItem.options = {};
  }

  const basePrice = selectedMenuItem.price;
  const hasAdjustments = optionCategoryIds.some(
    (key) =>
      // changes object has the optionCategory with a valid value
      changes.hasOwnProperty(key) && changes[key]
  );

  // Compute adjusted price
  let adjustedPrice = basePrice;
  if (hasAdjustments) {
    for (let optionCategoryId in changes) {
      if (
        changes.hasOwnProperty(optionCategoryId) &&
        changes[optionCategoryId] &&
        optionCategoryIds.indexOf(optionCategoryId) >= 0
      ) {
        const optionId = changes[optionCategoryId];
        const option = optionLookup[optionId];
        // Add to the options list
        selectedMenuItem.options[optionCategoryId] = [option];
        if (option.price) {
          adjustedPrice = adjustedPrice + option.price;
        }
      }
    }
  } else {
    for (let optionCategoryId in categoryDefaultOptionLookup) {
      if (
        optionCategoryIds.indexOf(optionCategoryId) >= 0 &&
        categoryDefaultOptionLookup[optionCategoryId]
      ) {
        const option = categoryDefaultOptionLookup[optionCategoryId];
        // Add to the options list
        selectedMenuItem.options[optionCategoryId] = [option];
        if (option.price) {
          adjustedPrice = adjustedPrice + option.price;
        }
      }
    }
  }

  return round(adjustedPrice * quantity, 2);
};

const MenuItemSelectionForm = (props) => {
  const { selectedMenuItem, control } = props;
  const spiceLevels = selectedMenuItem.spice_levels
    ? selectedMenuItem.spice_levels.split(",")
    : [];
  return (
    <>
      <Form>
        {selectedMenuItem.category_options &&
          selectedMenuItem.category_options.map((option_category) => {
            return (
              <Form.Group className="mb-3" key={option_category.id}>
                <Form.Label>{option_category.name}</Form.Label>
                <Controller
                  name={`${option_category.id}`}
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <Form.Select
                        className="form-control"
                        value={value}
                        onChange={onChange}
                      >
                        {option_category.options.map((option) => {
                          const optionName =
                            option.price === 0
                              ? `${option.name}`
                              : option.price > 0
                              ? `${option.name} [Add ${displayCurrency(
                                  option.price
                                )}]`
                              : `${option.name} [Subtract ${displayCurrency(
                                  option.price
                                )}]`;
                          return (
                            <option key={option.id} value={option.id}>
                              {optionName}
                            </option>
                          );
                        })}
                      </Form.Select>
                    );
                  }}
                />
              </Form.Group>
            );
          })}
        <Form.Group className="mb-3">
          <Form.Label>Quantity</Form.Label>
          <Controller
            name="quantity"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <Form.Select
                  className="form-control"
                  value={value}
                  onChange={onChange}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </Form.Select>
              );
            }}
          />
        </Form.Group>
        {spiceLevels.length > 0 && (
          <Form.Group className="mb-3">
            <Form.Label>Spice Level</Form.Label>
            <Controller
              name="spiceLevel"
              control={control}
              defaultValue={spiceLevels[0]}
              render={({ field: { value, onChange } }) => {
                return (
                  <Form.Select
                    className="form-control"
                    value={value}
                    onChange={onChange}
                  >
                    {spiceLevels.map((spiceLevel, index) => {
                      return (
                        <option key={index} value={spiceLevel}>
                          {spiceLevel}
                        </option>
                      );
                    })}
                    ;
                  </Form.Select>
                );
              }}
            />
          </Form.Group>
        )}
        <Form.Group className="mb-3">
          <Form.Label>Special Instruction (Optional)</Form.Label>
          <Controller
            name="instructions"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={value}
                  onChange={onChange}
                  maxLength={128}
                />
              );
            }}
          />
        </Form.Group>
      </Form>
    </>
  );
};

const MenuItemSelectionModal = (props) => {
  const { show, selectedMenuItem, handleModalAction, handleModalClose } = props;

  if (!selectedMenuItem) {
    return null;
  }
  const itemPurchaseable =
    selectedMenuItem && selectedMenuItem.purchase_enabled;

  // Setup form and watch for changes
  const spiceLevels = selectedMenuItem.spice_levels
    ? selectedMenuItem.spice_levels.split(",")
    : [];
  const defaultValues = {
    quantity: 1,
    instructions: "",
    spiceLevel: spiceLevels.length > 0 ? spiceLevels[0] : "",
  };
  const { handleSubmit, control } = useForm(defaultValues);
  const watch = useWatch({ control });

  // Setup item total initial value and update it when any form fields change
  let itemTotal = applyAdjustments(selectedMenuItem, watch);

  const onModalSubmit = (data) => {
    const result = {
      itemTotal,
      selectedMenuItem: cloneDeep(selectedMenuItem),
      quantity: parseInt(data.quantity || defaultValues.quantity),
      instructions: data.instructions || defaultValues.instructions,
      spiceLevel: data.spiceLevel || defaultValues.spiceLevel,
      options: selectedMenuItem.options,
    };
    handleModalAction(result);
    // Reset the options on the selected menu item if present
    if (selectedMenuItem.hasOwnProperty('options')) {
      selectedMenuItem.options = {};
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleModalClose} centered>
        <Modal.Header>
          <Modal.Title>{selectedMenuItem.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{selectedMenuItem.description}</p>
          <p>
            <span>Price: {displayCurrency(selectedMenuItem.price)}</span>
            <br />
            {itemPurchaseable && (
              <span>
                <strong>
                  Current item total: {displayCurrency(itemTotal)}
                </strong>
              </span>
            )}
          </p>
          {itemPurchaseable && (
            <MenuItemSelectionForm
              selectedMenuItem={selectedMenuItem}
              control={control}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleModalClose}
            className="outline"
          >
            Cancel
          </Button>
          {itemPurchaseable && (
            <Button
              variant="primary"
              onClick={handleSubmit(onModalSubmit)}
              className="btn_1"
            >
              Add Item
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MenuItemSelectionModal;
