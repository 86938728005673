import React from "react";

const MainHeader = (props) => {
  const {
    tenantName,
    tenantBannerUrl,
    tenantBannerWebsiteUrl,
    tenantDisplayAddress,
    tenantGoogleRevews,
    tenantGoogleDirections,
    tenantSiteCategory,
  } = props;

  return (
    <div
      className="hero_in detail_page background-image"
      style={{ backgroundImage: `url(${tenantBannerUrl})` }}
    >
      <div
        className="wrapper opacity-mask"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      >
        <div className="container">
          <div className="main_info">
            <div className="row">
              <div className="col-xl-4">
                {tenantGoogleRevews && (
                  <div className="head">
                    <div className="score">
                      <strong>{tenantGoogleRevews}</strong> Google Reviews
                    </div>
                  </div>
                )}
                <h1>{tenantName}</h1>
                {tenantSiteCategory}
                <br />
                {tenantDisplayAddress}
              </div>
              <div className="col-xl-8">
                <div className="buttons clearfix">
                  {tenantGoogleDirections && (
                    <a
                      href={tenantGoogleDirections}
                      className="btn_hero mr-1"
                      target="blank"
                    >
                      <i className="icon_map_alt"></i>Directions
                    </a>
                  )}
                  {tenantBannerWebsiteUrl && (
                    <a
                      href={tenantBannerWebsiteUrl}
                      className="btn_hero"
                      target="blank"
                    >
                      <i className="icon_laptop"></i>Website
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
