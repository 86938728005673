import React, { useEffect, useRef } from "react";

const AlertModal = (props) => {
  const { title, body, dismissText } = props;
  const alertModalWrapperRef = useRef(null);

  useEffect(() => {
    if (!alertModalWrapperRef.current) return;

    const alertModal =
      alertModalWrapperRef.current.querySelector("#alertModal");
    $(alertModal).modal("show");
  }, []);

  return (
    <div ref={alertModalWrapperRef}>
      <div
        id="alertModal"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="alertModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">{body}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                {dismissText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertModal;
