import React from "react";

const INVALID_PICKUP_TIME_MESSAGE =
  "This order has expired. Please start a new order to continue.";
const PENDING_MESSAGE =
  "We are still processing your order. This page will reload automatically when complete.";
const PAID_MESSAGE = "We have successfully processed your order!🎉";
const FAILED_MESSAGE =
  "This order failed due to an error. Please create a new one to continue.";
const REFUNDED_MESSAGE =
  "This order has been canceled. Please contact our restaurant directly for more details.";
const PAYMENT_MESSAGE = "Please make the payment below to complete your order.";

const REFUND_NOTIFICATION =
  "* Please note that it may take up to 10 days for the refund to appear on your account.";

const ORDER_PENDING = "PENDING";
const ORDER_FAILED = "FAILED";
const ORDER_NEW = "NEW";
const ORDER_COMPLETED = "COMPLETED";
const ORDER_CANCELLED = "CANCELLED";

const isPending = (status) => status === ORDER_PENDING;
const isPaid = (status) => [ORDER_NEW, ORDER_COMPLETED].includes(status);
const isRefunded = (status) => [ORDER_CANCELLED].includes(status);
const isFailed = (status) => [ORDER_FAILED].includes(status);
const shouldShowPayment = (status, isInvalidPickupTime) => {
  return (
    !isPaid(status) &&
    !isRefunded(status) &&
    !isFailed(status) &&
    !isInvalidPickupTime
  );
};

const renderStatusNotification = (props) => {
  const { order, isInvalidPickupTime } = props;
  const status = order.status;

  let statusMessageContext = {
    message: undefined,
    messageTextStyle: "text-danger",
  };
  if (isPending(status)) {
    statusMessageContext.message = PENDING_MESSAGE;
  } else if (isPaid(status)) {
    statusMessageContext.message = PAID_MESSAGE;
    statusMessageContext.messageTextStyle = "text-secondary";
  } else if (isFailed(status)) {
    statusMessageContext.message = FAILED_MESSAGE;
  } else if (isRefunded(status)) {
    statusMessageContext.message = REFUNDED_MESSAGE;
  } else if (shouldShowPayment(status, isInvalidPickupTime)) {
    statusMessageContext.message = PAYMENT_MESSAGE;
  } else if (isInvalidPickupTime) {
    statusMessageContext.message = INVALID_PICKUP_TIME_MESSAGE;
  }

  if (!statusMessageContext.message) {
    return null;
  }

  if (isPaid(status)) {
    localStorage.removeItem("app-cart-data");
    localStorage.removeItem("ydc-items");
    localStorage.removeItem("ydc-expiration");
  }

  return (
    <div className="mt-4">
      <span className={`pb-4 ${statusMessageContext.messageTextStyle}`}>
        {statusMessageContext.message}
      </span>
      {isPaid(status) && (
        <span>
          Here is your{" "}
          <a
            href={order.receipt_url}
            target="_blank"
            style={{ color: "#c51c23" }}
          >
            receipt link.
          </a>
        </span>
      )}
    </div>
  );
};

const renderCheckoutOfferSelection = (order) => {
  if (!order || order.checkout_offers.length < 1) {
    return null;
  }

  const checkoutOffer = order.checkout_offers[0];
  const description = checkoutOffer.description || "";
  const userSelection = checkoutOffer?.user_selection?.value;
  const text = userSelection ? `${description}: ${userSelection}` : description;
  return (
    <p>
      <span>
        <strong>PROMO OFFER:</strong>
      </span>
      <br />
      <span>{text}</span>
    </p>
  );
};

const renderOrderLineItems = (props) => {
  const { order, isInvalidPickupTime, restaurantUrl, csrfToken, paymentUrl } =
    props;

  const status = order.status;
  const lineItems = order.details.items;
  const truncatedItemName = (name) => {
    if (name.length > 80) {
      return name.substring(0, 80) + "...";
    }
    return name;
  };

  return (
    <div className="box_order mt-4">
      <div className="head">
        <h3>Order Detail</h3>
      </div>
      <div className="main">
        <ul className="clearfix">
          {lineItems.map((item, index) => {
            return (
              <li key={`${item.id}-${index}`}>
                <strong>{item.count} x </strong> {truncatedItemName(item.name)}{" "}
                <span>${item.subtotal}</span>
                <div style={{ "margin-left": "20px" }}>
                  {item.spice_level && (
                    <>
                      <span style={{ float: "left" }}>{item.spice_level}</span>
                      <br />
                    </>
                  )}
                  {item.display_options &&
                    item.display_options.map((option) => {
                      return (
                        <>
                          <span key={option} style={{ float: "left" }}>
                            {option}
                          </span>
                          <br />
                        </>
                      );
                    })}
                </div>
              </li>
            );
          })}
        </ul>
        {renderCheckoutOfferSelection(order)}
        <hr />
        <ul>
          <li>
            Subtotal<span>{order.display_amount_subtotal}</span>
          </li>
          <li>
            Discount<span>{order.display_amount_discount}</span>
          </li>
          {order.applied_coupons.map((coupon, index) => {
            return (
              <li key={`${coupon.id}-${index}`}>
                Applied coupon: {coupon.code}
              </li>
            );
          })}
          <li>
            Tip<span>{order.display_amount_tip}</span>
          </li>
          <li>
            Taxes and fees<span>{order.display_amount_tax}</span>
          </li>
          <li className="total">
            Total<span>{order.display_amount_total}</span>
          </li>

          {isRefunded(status) && (
            <div className="text-left pt-4">
              <span>{REFUND_NOTIFICATION}</span>
            </div>
          )}
          {isPaid(status) && (
            <div className="text-center">
              <a href={restaurantUrl} className="btn_1 outline">
                Start New Order
              </a>
            </div>
          )}
          {shouldShowPayment(status, isInvalidPickupTime) && paymentUrl && (
            <div className="text-center">
              <form action={paymentUrl} method="post">
                <input
                  type="hidden"
                  name="authenticity_token"
                  value={csrfToken}
                />
                <button type="submit" className="btn_1 outline">
                  Make Payment
                </button>
                <a href={restaurantUrl} className="ml-2 btn_1 outline">
                  Go Back
                </a>
              </form>
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

const OrderDetail = (props) => {
  return (
    <div>
      {renderStatusNotification(props)}
      {renderOrderLineItems(props)}
    </div>
  );
};

export default OrderDetail;
