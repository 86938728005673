import React from "react";
import MainHeader from "../shared/main_header";
import Form from "./form";

const New = (props) => {
  return (
    <main>
      <MainHeader {...props} />
      <div className="row mt-4">
        <div className="col-md-8 offset-md-2 col-sm-10 offset-sm-1">
          <div className="modal-content">
            <div className="modal-header">
              <h2 id="reserveModalLabel" className="modal-title text-center">
                Table Reservation
              </h2>
            </div>
            <div className="modal-body">
              <Form {...props} />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default New;
