import React, { useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { Toaster } from "react-hot-toast";
import Form from "react-bootstrap/Form";
import { Row, Col } from "react-bootstrap";
import MenuOrderSummaryUserForm from "./menuOrderSumaryUserForm";

const round = (value, decimals) => {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
};
const calculateSubtotal = (items) => {
  const result = items.reduce((total, item) => {
    return total + item.itemTotal;
  }, 0);
  return round(result, 2);
};
const calcuateTip = (subtotal, tipPreset) => {
  return round(subtotal * (tipPreset / 100), 2);
};
const calculateDiscount = (subtotal, promoRate) => {
  return round(subtotal * (promoRate / 100), 2);
};
const calcuateTax = (subtotal, taxRate) => {
  return round(subtotal * (taxRate / 100), 2);
};
const calculateTotal = (subtotal, tip, discount, tax) => {
  return round(subtotal + tip + tax - discount, 2);
};

const displayCurrency = (value) => {
  return `$${value.toFixed(2)}`;
};

const getOperandValue = (operand, subtotal) => {
  switch (operand) {
    case "subtotal":
      return subtotal;
    default:
      return null;
  }
};

const getEligibleCheckoutOffers = (options, subtotal) => {
  // Priority with a lower value is considered a higher priority
  const sortedOptions = options.sort((a, b) => a.priority - b.priority);

  let result = [];
  for (const option of sortedOptions) {
    if (option.requirements && option.requirements.conditions) {
      const conditions = option.requirements.conditions;
      const satisfiesConditions = conditions.every((condition) => {
        const operandValue = getOperandValue(condition.operand, subtotal);
        switch (condition.operator) {
          case "eq":
            return operandValue === condition.value;
          case "gte":
            return operandValue >= condition.value;
          case "gt":
            return operandValue > condition.value;
          case "lte":
            return operandValue <= condition.value;
          case "lt":
            return operandValue < condition.value;
          default:
            return false;
        }
      });

      if (satisfiesConditions) {
        result.push(option);
      }
    }
  }
  return result;
};

const renderInstructions = (instructions) => {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>{instructions}</Popover.Body>
    </Popover>
  );
  return (
    <Row>
      <Col xs="5">
        <OverlayTrigger
          trigger="click"
          placement="right"
          overlay={popover}
          rootClose
        >
          <p className="text-secondary">
            <u>Review Item Note</u>
          </p>
        </OverlayTrigger>
      </Col>
    </Row>
  );
};

const renderSelectedOptions = (item) => {
  const keys = Object.keys(item.options);
  return keys.map((optionCategory) =>
    item.options[optionCategory].map((option) => (
      <li key={option.id}>
        <pre>{option.name}</pre>
      </li>
    ))
  );
};

const renderItem = (item, index, handleRemoveItem) => {
  const hasInstructions = item.instructions && item.instructions != "";
  const text = `${item.quantity} x ${item.selectedMenuItem.name}`;
  return (
    <li key={`${item.selectedMenuItem.id}-${index}`}>
      <a
        style={{ cursor: "pointer" }}
        href="#"
        onClick={(e) => {
          e.preventDefault();
          handleRemoveItem(index);
        }}
      >
        <span>{text}</span>
      </a>
      <span>{displayCurrency(item.itemTotal)}</span>
      <br />
      <ul>
        <li key="spice-level">
          {item.spiceLevel && <pre>{item.spiceLevel}</pre>}
        </li>
        {item.options && renderSelectedOptions(item)}
      </ul>
      {hasInstructions && renderInstructions(item.instructions)}
    </li>
  );
};

const renderUserCheckoutOfferSelection = (
  eligibleCheckoutOffers,
  selectedCheckoutOfferIndex,
  selectedCheckoutOfferChoiceIndex,
  setSelectedCheckoutOfferChoiceIndex
) => {
  const checkoutOffer = eligibleCheckoutOffers[selectedCheckoutOfferIndex];
  if (!checkoutOffer || checkoutOffer.offer.type !== "user_selection") {
    return null;
  }

  const choices = checkoutOffer.offer.action.choices;
  return (
    <Form.Group className="mb-3">
      <Form.Select
        className="form-control"
        value={selectedCheckoutOfferChoiceIndex}
        onChange={(e) => {
          e.preventDefault();
          setSelectedCheckoutOfferChoiceIndex(e.target.value);
        }}
      >
        {choices.map((choice, index) => {
          return (
            <option key={index} value={index}>
              {choice.title}
            </option>
          );
        })}
      </Form.Select>
    </Form.Group>
  );
};

const MenuOrderSummary = (props) => {
  const {
    tenantApiBaseUrl,
    checkoutOffers,
    csrfToken,
    items,
    hours,
    promoRate,
    taxRate,
    handleRemoveItem,
    isModal,
    handleModalClose,
  } = props;
  const [discountRate, setDiscountRate] = useState(0);
  const [tipPreset, setTipPreset] = useState(10);
  const [selectedCheckoutOfferIndex, setSelectedCheckoutOfferIndex] =
    useState(undefined); // The initial selection is blank so that we can update this based on the promo eligibility
  const [
    selectedCheckoutOfferChoiceIndex,
    setSelectedCheckoutOfferChoiceIndex,
  ] = useState(0);

  const hasItems = items.length > 0;
  const subtotal = calculateSubtotal(items) || 0.0;
  const tip = calcuateTip(subtotal, tipPreset) || 0.0;
  const discount = calculateDiscount(subtotal, discountRate || 0.0);
  const tax = calcuateTax(subtotal, taxRate || 0.0);
  const total = calculateTotal(subtotal, tip, discount, tax);

  const eligibleCheckoutOffers = getEligibleCheckoutOffers(
    checkoutOffers,
    subtotal
  );

  const updateDiscountRate = (checkoutOffer) => {
    if (checkoutOffer.offer && checkoutOffer.offer.type === "discount") {
      setDiscountRate(checkoutOffer.offer.action.value);
    } else {
      setDiscountRate(0);
    }
  };

  // Update selected checkout offer if eligible
  if (eligibleCheckoutOffers.length > 0 && !selectedCheckoutOfferIndex) {
    setTimeout(() => {
      updateDiscountRate(eligibleCheckoutOffers[0]);
      setSelectedCheckoutOfferIndex(0);
    }, 10);
  }

  // Callback function to retrive order amounts
  const getOrderTotalCost = (field) => {
    switch (field) {
      case "subtotal":
        return subtotal;
      case "tip":
        return tip;
      case "discount":
        return discount;
      case "tax":
        return tax;
      case "total":
        return total;
    }
  };

  // Callback function to retrive user selected checkout offer
  const getCheckoutOffer = () => {
    let result = [];
    const checkoutOffer = eligibleCheckoutOffers[selectedCheckoutOfferIndex];
    if (checkoutOffer && checkoutOffer.offer) {
      const checkoutOfferAction = checkoutOffer.offer.action;
      const choice =
        checkoutOfferAction && checkoutOfferAction.choices
          ? checkoutOfferAction.choices[selectedCheckoutOfferChoiceIndex]
          : null;
      result.push({
        id: checkoutOffer.id,
        title: checkoutOffer.title,
        priority: checkoutOffer.priority,
        description: checkoutOffer.description,
        user_selection: {
          value: choice?.value,
        },
      });
    }
    return result;
  };

  return (
    <>
      <div className="box_order">
        <div className="head">
          <h3>ORDER SUMMARY</h3>
        </div>

        <div className="main">
          <ul>
            {items.map((item, index) =>
              renderItem(item, index, handleRemoveItem)
            )}
          </ul>

          {hasItems && (
            <ul className="text-right mt-4">
              <li>Tip preset</li>
              <Form.Select
                value={tipPreset}
                onChange={(e) => {
                  e.preventDefault();
                  const preset = parseInt(e.target.value);
                  setTipPreset(preset);
                }}
              >
                <option key={0} value="0">
                  0
                </option>
                <option key={10} value="10">
                  10%
                </option>
                <option key={15} value="15">
                  15%
                </option>
                <option key={18} value="18">
                  18%
                </option>
                <option key={20} value="20">
                  20%
                </option>
                <option key={25} value="25">
                  25%
                </option>
                <option key={50} value="50">
                  50%
                </option>
              </Form.Select>
            </ul>
          )}

          <ul>
            <li>
              Subtotal<span>{displayCurrency(subtotal)}</span>
            </li>
            <li>
              Tip<span>{displayCurrency(tip)}</span>
            </li>
            {hasItems && discount > 0.0 && (
              <li>
                Discount<span>{displayCurrency(discount)}</span>
              </li>
            )}
            {hasItems && tax > 0.0 && (
              <li>
                Estimated taxes and fees<span>{displayCurrency(tax)}</span>
              </li>
            )}
            <li className="total">
              Total<span>{displayCurrency(total)}</span>
            </li>
          </ul>

          {hasItems && eligibleCheckoutOffers.length > 0 && (
            <>
              <Form.Group className="mb-3">
                <Form.Label className="mr-2">
                  <span>Select an offer to apply to your order</span>
                </Form.Label>
                <Form.Select
                  className="form-control"
                  value={selectedCheckoutOfferIndex}
                  onChange={(e) => {
                    e.preventDefault();

                    const index = e.target.value;
                    setSelectedCheckoutOfferIndex(index);
                    setSelectedCheckoutOfferChoiceIndex(0);
                    updateDiscountRate(eligibleCheckoutOffers[index]);
                  }}
                >
                  {eligibleCheckoutOffers.map((checkoutOffer, index) => {
                    return (
                      <option key={checkoutOffer.id} value={index}>
                        {checkoutOffer.description}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>

              {renderUserCheckoutOfferSelection(
                eligibleCheckoutOffers,
                selectedCheckoutOfferIndex,
                selectedCheckoutOfferChoiceIndex,
                setSelectedCheckoutOfferChoiceIndex
              )}
            </>
          )}

          {hasItems && (
            <MenuOrderSummaryUserForm
              tenantApiBaseUrl={tenantApiBaseUrl}
              csrfToken={csrfToken}
              items={items}
              hours={hours}
              isModal={isModal}
              handleModalClose={handleModalClose}
              getOrderTotalCost={getOrderTotalCost}
              getCheckoutOffer={getCheckoutOffer}
            />
          )}
        </div>
      </div>
      <Toaster position="top-right" />
    </>
  );
};

export default MenuOrderSummary;
